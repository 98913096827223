import React from 'react'
import { PageProps, Link } from 'gatsby'
import Layout from '../../../components/Layout'
import { Paragraph, Title } from '../../../components/Typography'
import { Link as MuiLink } from '@material-ui/core'

const Success: React.FC<PageProps> = () => (
  <Layout path="Contact" meta={[{ name: 'robots', content: 'noindex' }]}>
    <Title>取り置き完了</Title>
    <Paragraph>
      取り置きを受け付けました。ご予約ありがとうございます。
    </Paragraph>
    <Paragraph>
      <MuiLink component={Link} to="/contact/torioki/">
        フォームへ戻る
      </MuiLink>
    </Paragraph>
  </Layout>
)

export default Success
